import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const StyledSiteNavigation = styled.div`
  align-self: flex-start;
  margin-top: 10rem;
  position: sticky;
  top: 10rem;
  z-index: 2;

  @media (max-width: 99rem) {
    display: none;
  }
`;

const StyledSiteNavigationContent = styled.div`
  position: absolute;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 2rem;
  color: white;
  font-size: 1.5rem;
  line-height: 1.5;
  border-right: 1px solid white;
`;

const StyledEntry = styled.div<{ active: boolean }>`
  margin-bottom: 0.5rem;
  white-space: nowrap;

  ${(props) =>
    props.active &&
    css`
      font-weight: bold;
    `}
`;

interface Element {
  text: string;
  element: React.RefObject<HTMLElement>;
}

interface Props {
  elements: Element[];
}

const SiteNavigation: React.FC<Props> = (props) => {
  const [activeElementIndex, setActiveElementIndex] = useState<number>(0);

  useEffect(() => {
    let intersectionHeight = 0;

    const observer = new IntersectionObserver(
      (e) => {
        if (e.length === 1) {
          const entry = e[0];
          const firstEntryIndex = props.elements.findIndex(
            (element) => element.element.current === entry.target
          );

          const isCurrent =
            props.elements[activeElementIndex].element.current === entry.target;
          if (isCurrent) {
            intersectionHeight = entry.intersectionRect.height;
          } else if (entry.intersectionRect.height > intersectionHeight) {
            setActiveElementIndex(firstEntryIndex);
            intersectionHeight = entry.intersectionRect.height;
          }
        } else if (e.length > 0) {
          // happens often after reload when both parts are visible
          let maxIntersectionHeight = 0;
          e.forEach((entry) => {
            if (entry.intersectionRect.height > maxIntersectionHeight) {
              maxIntersectionHeight = entry.intersectionRect.height;
            }
          });
          const maxHeightEntryIndex = e.findIndex(
            (entry) => entry.intersectionRect.height === maxIntersectionHeight
          );
          setActiveElementIndex(maxHeightEntryIndex);
          intersectionHeight = maxIntersectionHeight;
        }
      },
      { threshold: new Array(100).fill(0).map((_, i) => (i + 1) / 100) }
    );

    props.elements.forEach((element) => {
      if (element.element.current != null) {
        observer.observe(element.element.current);
      }
      return observer;
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <StyledSiteNavigation>
      <StyledSiteNavigationContent>
        {props.elements.map((element, index) => (
          <StyledEntry
            active={index === activeElementIndex}
            onClick={() =>
              element.element.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            {element.text}
          </StyledEntry>
        ))}
      </StyledSiteNavigationContent>
    </StyledSiteNavigation>
  );
};

export default SiteNavigation;
