import React, { useRef } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import ArrowsWhitePath from "../images/arrows-white.svg";
import BackgroundImage1Path from "../images/main/nxrt-mainpage.jpg";
import BackgroundImage2Path from "../images/main/nxrt-mainpage3.jpg";
import BackgroundImage3Path from "../images/main/nxrt-mainpage2.jpg";
import SiteNavigation from "../components/siteNavigation";
import { Routes } from "../components/layout/header";
import Video1Path from "../images/videos/mr.mp4";
import Video2Path from "../images/videos/tech.mp4";
import Video3Path from "../images/videos/can.mp4";
import SpatialComputingImage1Path from "../images/main/nxrt-mainpage5.jpg";
import SpatialComputingImage2Path from "../images/main/nxrt-mainpage6.jpg";
import SpatialComputingImage3Path from "../images/main/nxrt-mainpage7.jpg";
import SpatialComputingImage4Path from "../images/main/nxrt-mainpage4.jpg";
import SpatialComputingImage5Path from "../images/main/nxrt-mainpage8.jpg";

//#region Styled Components

//#region Hero
const StyledHeroSection = styled.section`
  min-height: 900px;
  padding: 7rem 5rem 0 5rem;
  padding-bottom: 18rem;
  background-color: #000d1d;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StyledHeroHeaderContainer = styled.div`
  max-width: 1344px;
  display: flex;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
`;

const StyledHeroHeader = styled.h1`
  text-transform: uppercase;
  line-height: 1;

  > span {
    display: inline-block;
  }

  > span:nth-of-type(1) {
    margin-left: 4rem;
    font-weight: 300;
    font-size: 3rem;
  }

  > span:nth-of-type(2) {
    margin-left: 8rem;
    font-weight: 700;
    font-size: 3.5rem;

    > span {
      font-weight: 100;
      font-size: 3rem;
    }
  }

  > span:nth-of-type(3) {
    font-weight: 300;
    font-size: 4rem;
  }

  > span:nth-of-type(4) {
    margin-left: 8.5rem;
    font-weight: 700;
    font-size: 4rem;
  }
`;

const StyledHeroDescriptionContainer = styled.div`
  max-width: 1354px;
  width: 100%;
  z-index: 1;
  pointer-events: none;
`;

const StyledHeroDescription = styled.div`
  margin-top: 3rem;
  max-width: 780px;
  padding: 20px;
  align-self: flex-start;
  border: 1px solid white;
  font-size: 1.3rem;
  line-height: 1.5;

  @media (max-width: 64rem) {
    margin-top: 10rem;
  }

  @media (max-width: 45rem) {
    margin-top: 5rem;
  }
`;

const StyledImageBackgrounds = styled.div`
  max-width: 1354px;
  width: calc(100% - 10rem);
  opacity: 0.6;
  position: absolute;
  display: flex;
  flex-wrap: wrap;

  > img {
    margin: 1.25%;
    align-self: flex-start;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;

    :hover {
      transform: translateY(-8px);
    }
  }

  > img:nth-child(1) {
    width: 30%;
  }

  > img:nth-child(2) {
    align-self: flex-end;
    width: 60%;
    margin-top: 130px;
  }

  > img:nth-child(3) {
    width: 30%;
    margin-left: 60%;
  }
`;

const StyledHeroArrowsContainer = styled.div`
  margin-top: 7rem;
  max-width: 1354px;
  width: 100%;
  z-index: 1;
  height: 1px;
  margin-bottom: 10rem;
`;

const StyledHeroArrows = styled.img`
  margin-left: 5rem;
  height: 10rem;
`;
//#endregion

//#region Realtime immersion
const StyledRealtimeImmersionSection = styled.section`
  padding: 10rem 10rem 0 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-top: 0;
    font-size: 3rem;
    text-transform: uppercase;
    text-align: center;
  }

  @media (max-width: 50rem) {
    padding: 5rem;
  }
`;

const StyledContentWrapper = styled.div`
  min-height: 100vh;
  width: calc(100% + 20rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 62rem) {
    width: 100%;
  }
`;

const StyledVideoOverlay = styled.div`
  top: -15rem;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: radial-gradient(
    closest-side ellipse at center 45%,
    rgba(34, 43, 54, 0) 0%,
    #000d1d 65%
  );

  @media (max-width: 62rem) {
    display: none;
  }
`;

const StyledVideoContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100%;

  > video {
    position: relative;
    top: -15rem;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    filter: grayscale(1);

    object-fit: contain;
  }

  @media (max-width: 62rem) {
    display: none;
  }
`;

const StyledContentContainer = styled.div`
  padding-top: 15rem;
  padding-bottom: 15rem;
  width: 100%;
  max-width: 45rem;
  z-index: 1;

  > p {
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-weight: 300;
  }
`;

const StyledContentHeadline = styled.div`
  margin-bottom: 3rem;
  position: relative;

  > h3 {
    margin: 0;
    font-weight: normal;
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    position: relative;
  }

  > span {
    position: absolute;
    font-size: 15rem;
    line-height: 1;
    left: 50%;
    bottom: -5rem;
    transform: translateX(-50%);
    color: gray;
  }
`;

const StyledRealtimeImmersonArrows = styled.img`
  height: 10rem;
  z-index: 1;
`;
//#endregion

//#region Spatial computing
const StyledSpatialComputingSection = styled.section`
  padding: 0 25rem 10rem 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 6rem;
    font-size: 4rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 100;
    letter-spacing: 0.5rem;
  }

  @media (max-width: 99rem) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

const StyledSpatialComputingContent = styled.div`
  max-width: 69rem;
  margin-bottom: 2rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto;

  img {
    width: 100%;
    align-self: stretch;
    height: 100%;
    object-fit: cover;
  }

  > :nth-child(1) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  > :nth-child(2) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    height: auto;
  }

  > :nth-child(3) {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }

  > :nth-child(4) {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
    height: auto;
  }

  > :nth-child(5) {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
  }

  > :nth-child(6) {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
  }

  > :nth-child(7) {
    grid-row: 4 / 5;
    grid-column: 2 / 3;
    height: auto;
  }

  @media (max-width: 62rem) {
    > :nth-child(1) {
      grid-row: 1 / 3;
    }

    > :nth-child(2) {
      display: none;
    }
  }
`;

const StyledSpatialComputingTextbox = styled.div`
  max-width: 69rem;
  padding: 2rem;
  border: 1px solid white;

  > h3 {
    margin-top: 0;
    font-size: 2rem;
    font-weight: 300;
  }

  > p {
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: 300;
  }
`;
//#endregion

//#region Navigation
const StyledNavigationContentContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: #000d1d;
  background-size: cover;
  position: relative;

  @media (max-width: 1366px) {
    padding: 0;
  }
`;

const StyledNavigationContent = styled.div`
  flex-grow: 1;
`;
//#endregion

//#endregion

const IndexPage: React.FC = () => {
  const realtimeImmersionRef = useRef();
  const spatialComputingRef = useRef();

  return (
    <Layout currentRoute={Routes.Home}>
      <StyledHeroSection>
        <StyledHeroHeaderContainer>
          <StyledHeroHeader>
            <span>Industry</span>
            <br />
            <span>
              Leader <span>in</span>
            </span>
            <br />
            <span>Realtime</span>
            <br />
            <span>Immersion</span>
          </StyledHeroHeader>
        </StyledHeroHeaderContainer>
        <StyledHeroDescriptionContainer>
          <StyledHeroDescription>
            <p>
              <strong>Realtime immersion</strong> is the defining principle for
              XR First Systems, promising highest physical comfort and sense of
              presence through sophisticated visual performance, dynamic
              environments and realistic haptic perception.
            </p>
          </StyledHeroDescription>
        </StyledHeroDescriptionContainer>
        <StyledHeroArrowsContainer>
          <StyledHeroArrows
            src={ArrowsWhitePath}
            onClick={() =>
              document
                .getElementById("content")
                .scrollIntoView({ behavior: "smooth" })
            }
          />
        </StyledHeroArrowsContainer>
        <StyledImageBackgrounds>
          <img src={BackgroundImage1Path} />
          <img src={BackgroundImage2Path} />
          <img src={BackgroundImage3Path} />
        </StyledImageBackgrounds>
      </StyledHeroSection>
      <StyledNavigationContentContainer>
        <SiteNavigation
          elements={[
            { text: "Realtime Immersion", element: realtimeImmersionRef },
            { text: "Spatial Computing", element: spatialComputingRef },
          ]}
        />
        <StyledNavigationContent id="content">
          <StyledRealtimeImmersionSection ref={realtimeImmersionRef}>
            <h2>Realtime Immersion</h2>
            <StyledContentWrapper>
              <StyledVideoContainer>
                <video loop muted autoPlay>
                  <source src={Video1Path} type="video/mp4" />
                </video>
                <StyledVideoOverlay />
              </StyledVideoContainer>
              <StyledContentContainer>
                <StyledContentHeadline>
                  <span>1</span>
                  <h3>Unique XR first:</h3>
                </StyledContentHeadline>
                <p>
                  Each of our solutions complies with "XR First" guidelines and
                  features streamlined interactions and workflows, blending the
                  physical world with the virtual to create a unique experience,
                  compelling narrative, and high degree of agency for each use
                  case. In any room, in a suit, a simulator or on a screen.
                </p>
              </StyledContentContainer>
              <StyledRealtimeImmersonArrows src={ArrowsWhitePath} />
            </StyledContentWrapper>
            <StyledContentWrapper>
              <StyledVideoContainer>
                <video loop muted autoPlay>
                  <source src={Video2Path} type="video/mp4" />
                </video>
                <StyledVideoOverlay />
              </StyledVideoContainer>
              <StyledContentContainer>
                <StyledContentHeadline>
                  <span>2</span>
                  <h3>Performance:</h3>
                </StyledContentHeadline>
                <p>
                  The RT Editor Suite provides our artists and engineers with
                  the best development environment and toolset to create
                  excellent simulations with lifelike visuals, high frame rates,
                  smooth motion, and a highly reactive and immersive AI within a
                  dynamic and changing virtual world.
                </p>
              </StyledContentContainer>
              <StyledRealtimeImmersonArrows src={ArrowsWhitePath} />
            </StyledContentWrapper>
            <StyledContentWrapper>
              <StyledContentContainer>
                <StyledVideoContainer style={{ zIndex: -1 }}>
                  <video loop muted autoPlay>
                    <source src={Video3Path} type="video/mp4" />
                  </video>
                  <StyledVideoOverlay />
                </StyledVideoContainer>
                <StyledContentHeadline>
                  <span>3</span>
                  <h3>Usability &amp; UX:</h3>
                </StyledContentHeadline>
                <p>
                  At the centre of all our developments is the human being.
                  Turning to technology alone is no guarantee of success; people
                  need to be able to utilise it and embrace it. Years of
                  experience with virtual worlds has taught us how to overcome
                  simulation sickness, avoid the uncanny valley, create
                  embodiment, offer the best user interaction, and a superb
                  human-machine interface.
                </p>
              </StyledContentContainer>
              <StyledRealtimeImmersonArrows src={ArrowsWhitePath} />
            </StyledContentWrapper>
          </StyledRealtimeImmersionSection>
          <StyledSpatialComputingSection ref={spatialComputingRef}>
            <h2>Spatial Computing</h2>
            <StyledSpatialComputingContent>
              <StyledSpatialComputingTextbox>
                <h3>NX3 [Sensor System]:</h3>
                <p>
                  Our sensor system can be mounted quickly and efficiently at
                  strategic points in the room or on a vehicle to provide
                  control of any simulation, based on natural input metaphors.
                  These sensors are small and accurate, providing a hidden and
                  invisible interface to the virtual experience.
                </p>
              </StyledSpatialComputingTextbox>
              <img src={SpatialComputingImage1Path} />
              <img src={SpatialComputingImage2Path} />
              <img src={SpatialComputingImage3Path} />
              <img src={SpatialComputingImage4Path} />
              <StyledSpatialComputingTextbox>
                <h3>MISHBILD:</h3>
                <p>
                  MISHBILD is our unique AI based spatial computing approach
                  which replaces real world areas with virtual graphics.
                  Mishbild combines the advantages of virtual reality, augmented
                  reality, passthrough vision and superb performance. It allows
                  a smooth transition between real and 3 dimensional virtual
                  worlds.
                </p>
              </StyledSpatialComputingTextbox>
              <img src={SpatialComputingImage5Path} />
            </StyledSpatialComputingContent>
            <StyledSpatialComputingTextbox>
              <h3>Simulation Experience:</h3>
              <p>
                Small and highly precise sensors attached to strategic positions
                transfers real inputs like steering, acceleration and brake
                changes into virtual inputs to steer the virtual vehicle as you
                do in real life.
              </p>
            </StyledSpatialComputingTextbox>
          </StyledSpatialComputingSection>
        </StyledNavigationContent>
      </StyledNavigationContentContainer>
    </Layout>
  );
};

export default IndexPage;
